import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

function LifeChart() {
  const MS_PER_DAY = 1000 * 60 * 60 * 24;
  const MS_PER_WEEK = MS_PER_DAY * 7;
  
  const [birthDate] = useState(new Date("1990-02-09"));
  const [numWeeks] = useState(90 * 52);
  const [weeksLived, setWeeksLived] = useState(0);
  const [hoveredWeek, setHoveredWeek] = useState(null);
  const [daysLeft, setDaysLeft] = useState(0);

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const endDate = new Date(
        birthDate.getFullYear() + 90,
        birthDate.getMonth(),
        birthDate.getDate()
      );
      
      setDaysLeft(Math.ceil((endDate - now) / MS_PER_DAY));
      setWeeksLived(Math.floor((now - birthDate) / MS_PER_WEEK));
    };

    calculateTimeLeft();
    const timer = setInterval(calculateTimeLeft, MS_PER_DAY);
    return () => clearInterval(timer);
  }, [birthDate]);

  const boxes = [];
  for (let i = 0; i < numWeeks; i++) {
    const isLived = i < weeksLived;
    const boxColor = isLived ? "#1b77f8" : "#C5C5C5";
    if (i === weeksLived) {
      boxes.push(
        <div className="weekBox"
          key={i}
          title={`Week ${i + 1}`}
          onMouseEnter={() => setHoveredWeek(i + 1)}
          onMouseLeave={() => setHoveredWeek(null)}
        >
          <span>{hoveredWeek === i + 1 ? hoveredWeek : null}</span>
        </div>
      );
    } else {
      boxes.push(
        <div
          key={i}
          title={`Week ${i + 1}`}
          onMouseEnter={() => setHoveredWeek(i + 1)}
          onMouseLeave={() => setHoveredWeek(null)}
          style={{
            width: "14px",
            height: "14px",
            backgroundColor: boxColor,
            margin: "1px"
          }}
        />
      );
    }
  }

  const rows = [];
  for (let i = 0; i < numWeeks / 52; i++) {
    rows.push(
      <div key={i} style={{ display: "flex" }}>
        {boxes.slice(i * 52, (i + 1) * 52)}
      </div>
    );
  }

  const progress = ((weeksLived + 1) / numWeeks) * 100;

  return (
    <div className="App">
      <header className="App-header">
        <div className="Box">
          <div>My Life in Weeks</div>
          <div className="progress-container">
            <progress value={progress} max="100"></progress>
            <span className="progress-text">{progress.toFixed(2)}%</span>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {rows}
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
              <div>Total Weeks: {numWeeks}</div>
              <div>90 Years</div>
            </div>
            <div className="days-left" style={{ marginTop: "20px", fontSize: "18px", fontWeight: "bold" }}>
              <p>There are approximately <span style={{ color: "#ff5733" }}>{daysLeft.toLocaleString()}</span> days left in this short life. Make them count.</p>
            </div>

            <div className="credits">
              <Link to="/Memories"><button className="button" type="submit">Want Even More?</button></Link>
            </div>
          </div>
        </div>

        <div className="credits">
          <p>This page is inspired by a few resources:</p>
          <ul>
            <li><a className="App-link" href="http://waitbutwhy.com/2014/05/life-weeks.html" target="_blank" rel="noopener noreferrer">Your Life in Weeks</a>, a post by Tim Urban, from <a className="App-link" href="http://waitbutwhy.com/">Wait But Why</a>.</li>
            <li><a className="App-link" href="https://us.macmillan.com/books/9780374159122" target="_blank" rel="noopener noreferrer">Four Thousand Weeks: Time Management for Mortals</a>, a book by <a className="App-link" href="https://www.oliverburkeman.com/books">Oliver Burkeman</a>.</li>
            <li><a className="App-link" href="https://jamesclear.com/atomic-habits" target="_blank" rel="noopener noreferrer">Atomic Habits</a>, a book by <a className="App-link" href="https://jamesclear.com/">James Clear</a>.</li>
          </ul>
          <p>Code inspired by / adopted from <a className="App-link" href="https://github.com/bryanbraun/your-life" target="_blank" rel="noopener noreferrer">Bryan Braun</a>.</p>
        </div>
      </header>
    </div>
  );
}

export default LifeChart;